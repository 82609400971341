import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { SoloCharacter } from '../../../modules/solo/common/components/solo-character';
import { SoloWeapon } from '../../../modules/solo/common/components/solo-weapon';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const SoloGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_beginner.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Beginner Guide</h1>
          <h2>
            A beginner guide for Solo Leveling: Arise that will help you start
            strong in the game.
          </h2>
          <p>
            Last updated: <strong>08/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Video version" />
        <p>
          If you prefer watching a video over reading, here's one that covers
          the beginner guide:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="AuxFd4lUsi4" />
          </Col>
        </Row>
        <SectionHeader title="Start and Progression" />
        <h5>Reroll</h5>
        <p>
          When you play the game you should first decide if you want to reroll
          or not.{' '}
          <strong>
            Rerolling doesn’t take much time after completing the tutorial the
            first time and is a great way to start the game with a few strong
            Hunters or weapons
          </strong>
          .
        </p>
        <p>
          You can find our reroll guide linked below but to keep it short, you
          should <strong>try to get the current featured pick-up Hunter</strong>{' '}
          and then focus your rolls on getting <strong>Seo and Emma</strong>{' '}
          (Min is another great character, as he can fit in all teams as a
          dedicated support). For weapons, considering you can get Scythe for
          free we suggest focusing on weapons that could work nicely with it,
          namely <strong>Plum Sword and Book of Thesis</strong>.{' '}
        </p>
        <p>Here are the three characters we mentioned:</p>
        <div className="employees-container solo-cards">
          <SoloCharacter
            slug="seo-jiwoo"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="emma-laurent"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
          <SoloCharacter
            slug="min-byung-gu"
            mode="card"
            showLabel
            showTags
            enablePopover
          />
        </div>
        <p>And here are the weapons we mentioned earlier:</p>
        <Row xs={1} xxl={2} className="solo-weapons-container">
          <Col>
            <SoloWeapon
              name="Demonic Plum Flower Sword"
              mode="card"
              showLabel
            />
          </Col>
          <Col>
            <SoloWeapon name="Thethis' Grimoire" mode="card" showLabel />
          </Col>
        </Row>
        <p>And here's our reroll guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/solo-leveling/guides/reroll"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_reroll.jpg"
                alt="Reroll guide"
              />
            }
          />
        </Row>
        <p>
          After you finish rerolling you should start pushing for{' '}
          <strong>Story Mode</strong> as far as you can. There is no need to
          focus on getting missions done in all stages as you can always revisit
          the previous missions when you are stronger, especially the ‘speedrun’
          missions requiring you to clear within x seconds.
        </p>
        <p>
          It’s worth noting that you can obtain a lot of{' '}
          <strong>Summoning Tickets</strong> for clearing the story, so you
          should{' '}
          <strong>
            avoid spending your precious Essences (premium currency) on the
            standard banner
          </strong>{' '}
          and only use it to pull on the Rate-up banner.
        </p>
        <h5>Gold issues</h5>
        <p>
          <strong>
            Gold is a HUGE bottleneck early on, so you should focus your
            resources on only a few Hunters and weapons
          </strong>
          . You will use a lot of Gold to upgrade everything in the game, so use
          it wisely. We suggest keeping an eye on our tier list for Hunters and
          Weapons, before investing into someone:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Hunter Tier List"
            link="/solo-leveling/tier-list"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_tier.jpg"
                alt="Tier List"
              />
            }
          />
          <CategoryCard
            title="Weapon Tier List"
            link="/solo-leveling/tier-list-weapons"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_tier2.jpg"
                alt="Tier List"
              />
            }
          />
        </Row>
        <p>
          <strong className="red">
            Overall, SR weapons are very important while progressing through the
            game
          </strong>
          , as you can obtain dupes more easily and they can give a lot of early
          power/precision. So don't sleep on the lower rarity Weapons (and don't
          dismantle them!) early on and we also suggest working on your{' '}
          <strong>Light weapons and Dark weapons for Jinwoo</strong>, as they
          will prove helpful in clearing some difficult story stages and farm
          artifacts.
        </p>
        <SectionHeader title="Stat allocation for Sung Jinwoo" />
        <StaticImage
          src="../../../images/solo/generic/beginner_stats.webp"
          alt="Tier List"
        />
        <p>
          It’s also important to properly allocate your stat points, as stats
          were not made equal.
        </p>
        <p>
          Players should focus mostly on{' '}
          <strong>Strength and Perception</strong> stats, as they are
          universally useful regardless of the weapon. How much Perception you
          need depends on the dupes of your weapons - since dupes will give you
          additional Precision, a stat that influences the minimum amount of
          damage when attacking. A maxed out SSR weapon provides 4000 points of
          Precision (2000 for the first dupe, 500 afterward) while a maxed SR
          weapon provides 2000.
        </p>
        <StaticImage
          src="../../../images/solo/generic/beginner_weapons.webp"
          alt="Guide"
        />
        <p>
          You can start out with a <strong>4:1 STR/Perception build</strong> and
          reset your stats as you get more dupes in your favorite weapons to get
          more points of STR.
        </p>
        <SectionHeader title="Equipment" />
        <StaticImage
          src="../../../images/solo/generic/beginner_eq.webp"
          alt="Guide"
        />
        <h5>Main and sub-stats</h5>
        <p>
          We can split equipment in two groups: left side and right side. Left
          side equipment can be obtained playing{' '}
          <strong>Gates and doing Encore Missions</strong>, while right side
          equipment can be obtained playing <strong>Instance Dungeons</strong>.
          More to this later!
        </p>
        <p>
          Some equipments will have fixed main stats while others have a
          randomly assigned one:
        </p>
        <ul>
          <li>
            For left side gear, Helmets can have additional or % based ATK, DEF
            and HP, Gloves and Chest will always give additional DEF, gloves
            will always give additional ATK and lastly, boots have crit damage,
            defense penetration, healing given increase %, defense %, hp%.{' '}
          </li>
          <li>
            For right side gear, Necklace will always give additional HP,
            Bracelets give element % damage up, Rings give additional or % based
            atk, HP or DEF and Earrings always give additional MP.
          </li>
        </ul>
        <p>
          <strong>Early on you should not upgrade your gear past +5</strong>, as
          you want to focus on getting purple gear or above as soon as possible.
          While substats are helpful, start by targeting the correct main stat
          first and then replace pieces as you get better substats. Also,{' '}
          <strong>
            percentage based increases are stronger later in the game as you
            gain more stats and increase your level, while ‘flat increases’ are
            very helpful while progressing considering they can quickly boost
            your stats
          </strong>
          .
        </p>
        <p>
          For a lot of Hunters, you can simply stack their preferred stat early
          on in order to clear the first tiers of Encore Missions.
        </p>
        <p>
          For Jinwoo you should focus on attack bonuses (be it additional or %
          based), critical hit chance and you can also raise pieces with defense
          ignore.{' '}
          <strong>
            Critical Hit Damage is currently bugged and only working when above
            the stage suggested CP
          </strong>
          , so when it gets fixed the value will increase significantly if your
          crit rate is high enough.
        </p>
        <p>
          If you don’t know what stats you should focus on, you can tap
          ‘Recommended Stats’ on the artifact page of your Hunter:
        </p>
        <StaticImage
          src="../../../images/solo/generic/beginner_sug.webp"
          alt="Guide"
        />
        <h5>Sets</h5>
        <p>
          <strong>
            Equipping pieces of the same sets will give you a set bonus for 2 or
            4 pieces
          </strong>
          . Obviously getting the desired sets is preferred, but when starting
          out don’t be afraid to use ‘broken’ sets or pieces as long as they
          have the correct stats. That being said, try to at least use a 2/4
          bonus as they can provide a lot of free stats - the popular Toughness
          set gives 8% crit rate at 2/4, while you can also get some % stats
          with sets like Noble Sacrifice (HP) or Armed (Atk%).
        </p>
        <p>
          For more details about the Stats and Sets check our dedicated guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Artifact Sets"
            link="/solo-leveling/guides/artifact-sets"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_arti.png"
                alt="Artifact Sets"
              />
            }
          />
          <CategoryCard
            title="Artifact Stats"
            link="/solo-leveling/guides/artifact-stats"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_arti2.png"
                alt="Artifact Stats"
              />
            }
          />
        </Row>
        <SectionHeader title="Skills, Runes and Blessings" />
        <StaticImage
          src="../../../images/solo/generic/beginner_skills.webp"
          alt="Guide"
        />
        <p>
          Skills are the bread and butter of Jinwoo and hunters, allowing them
          to deal damage or support their allies.
        </p>
        <h5>Hunter skills</h5>
        <p>
          Every Hunter has 2 basic attacks - a normal one and a ‘core attack’
          that happens when you fill the bar - two basic skills and four special
          skills.
        </p>
        <ul>
          <li>
            The first special skill is the character’s support skill when
            selected as an assist. Every dupe will increase this passive for a
            total of 1/3/5/7/10 extra levels. You can also level this passive
            using Hunter skill scrolls,
          </li>
          <li>
            The second is a QTE skill, Quick Time Event. These are the skills
            that Hunters use when doing a ‘switch in’ when you are doing a
            Hunter stage,
          </li>
          <li>
            The third one is their Ultimate skill and can only be used when the
            power gauge is full.
          </li>
          <li>
            The last one is the passive skill, that generally defines the
            character’s “identity” and adds unique mechanics to their kit. Every
            dupe will add one ‘star’ to the character, allowing them to get
            extra charges of their skills or even change how they work, morphing
            their existing skills in specific conditions like Choi’s first dupe.
          </li>
        </ul>
        <p>
          Levelling skills up costs Hunter Skill scrolls 1 up to level 4 and
          level 2 scrolls up to level 7.
        </p>
        <p>
          As you increase the skill level you will also need to reach a specific
          level in order to proceed with the upgrade.{' '}
          <strong>
            Level 8 and 9 upgrades require level 3 scrolls and Mana dusts, these
            are extremely rare and should not be used without a lot of
            consideration
          </strong>
          .
        </p>
        <h5>Jinwoo skills</h5>
        <StaticImage
          src="../../../images/solo/generic/beginner_skills2.webp"
          alt="Guide"
        />
        <p>
          In the case of Jinwoo, you will get more skills as you progress
          through the game.
        </p>
        <ul>
          <li>
            You can customize his skill layout to select two basic skills and
            one ultimate skill,
          </li>
          <li>
            You can also select the QTE skill when dodging and the ultimate
            skill.
          </li>
        </ul>
        <p>
          The skill upgrades are similar to the Hunter skill upgrades, they just
          use a different skill scroll type. Also, Jinwoo can level up the skill
          of his weapons.
        </p>
        <p>
          Every weapon has a Basic Attack skill, a Core Attack and an Ultimate,
          so you{' '}
          <strong>
            should upgrade the ones that you use the most first and then
            gradually increase the skill levels of your strongest weapons to
            increase your damage
          </strong>
          . Of course, apply discretion - if you only use a weapon as a ‘switch
          in’ for their ultimate, there is no immediate need to raise the basic
          attack or core attack skill.
        </p>
        <p>
          You can find the option of levelling up weapon skills in the ‘Weapon’
          menu after selecting the weapon you want to upgrade.
        </p>
        <h5>Runes</h5>
        <StaticImage
          src="../../../images/solo/generic/beginner_skills3.webp"
          alt="Guide"
        />
        <p>
          It’s very important to know that Jinwoo can also equip runes. Runes
          can be obtained by crafting and can change the skill they are equipped
          to, adding extra effects or changing the element of the attack.
          Unlocking a rune for a skill adds an extra skill level to it and every
          skill has <strong>four different runes that can be unlocked</strong>,
          even if you can only equip one at a time.
        </p>
        <p>
          Runes also come in different rarities:{' '}
          <strong>Basic, Epic and Legendary</strong>. Epic runes provide a
          stronger buff and adds 2 skill levels when equipped. You can craft
          runes with the Fusion menu.
        </p>
        <p>
          You can exchange 100 rune fragments for a random rune - most likely
          it’s going to be a basic rune. When you obtain a new rune it will be
          automatically added to the list of runes for that skill. However, if
          you obtain a rune that you already have, it will be stored and you can
          use it to try and craft a higher rarity rune with the skill fusion.
        </p>
        <p>
          The chance of obtaining a higher rarity rune is displayed on the
          screen and increases every time you fail to generate a higher rarity
          rune - the % will go back to default after you manage to get one.
          Should you get a dupe Epic Rune, you can do the same for the Legendary
          rune fusion.
        </p>
        <h5>Blessings</h5>
        <StaticImage
          src="../../../images/solo/generic/beginner_blessing.webp"
          alt="Guide"
        />
        <p>
          Considering the nature of runes, it’s a great time to mention the
          existence of Blessings.{' '}
          <strong>
            Blessings are basically ‘free’ passives that you can equip with a
            different set of effects
          </strong>
          . You start with only one Blessing slot and increase a new one as you
          hit level 50/60/70. Some blessings offer extra damage, others offer
          damage buffs or even extra abilities like the ‘Daily quest
          completion’.
        </p>
        <p>
          You can obtain Blessings by fusing blessing powder and the process is
          the same as the rune fragments - as you get dupes, you can try to fuse
          them for higher grade blessings and so on.
        </p>

        <SectionHeader title="Best Skills, Runes and Blessings for Jinwoo" />
        <p>
          To find the best skills and runes for Jinwoo, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best skills for Jinwoo"
            link="/solo-leveling/guides/jinwoo-skills"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_skill.jpg"
                alt="Best skills for Jinwoo"
              />
            }
          />
        </Row>
        <SectionHeader title="Army of Shadows" />
        <StaticImage
          src="../../../images/solo/generic/beginner_shadows.webp"
          alt="Guide"
        />
        <p>
          After progressing through the game (Chapter 9) you will unlock Jinwoos
          trademark skill - the Army of Shadow. The army is composed of generic
          shadows that do damage as well as named shadows that have special
          effects on the party, possess skills and overall contribute a lot to
          the battle.
        </p>
        <p>
          Your Army Level influences the Army Total Power and the maximum shade
          capabilities.{' '}
          <strong>You can level up your army with Shadow Energy</strong>, which
          can be obtained by playing the game - mostly in gates and story. While
          early on you will have to be a bit careful with the usage of your
          shadow army, as you progress you will start obtaining a lot of shades,
          that acts as a sort of ‘stamina’ for your army.
        </p>
        <p>
          Every time you bring your army to a mission, they will consume a set
          number of shades. You can see the total shades consumed per mission in
          the pre-battle menu, after tapping on your ‘named’ shadows and
          checking their party menu.
        </p>
        <h5>Named Shadows</h5>
        <p>
          Named shadows are more ‘elite’ shadows that will be deployed with the
          shadow army and are notable enemies turned into allies during the
          story. Some are original to the game like Blades, the elf that Jinwoo
          regret not being able to turn into a shadow. By default, you will have
          Igris as your first named shadow.
        </p>
        <p>
          Every named shadow has a Rank, a Growth level (much like Hunters), a
          passive skill and some skills that will be activated when you summon
          them.
        </p>
        <p>
          Increasing the growth level of your named shadows boosts your Army’s
          power. The materials needed for the upgrade can be obtained from gates
          as well as from the battlefield of time game mode. To increase the
          rank of your named shadow you need to use the same currency that you
          use to unlock shadows: Traces of Shadow.
        </p>
        <SectionHeader title="Game modes - Story mode" />
        <StaticImage
          src="../../../images/solo/generic/beginner_story.webp"
          alt="Guide"
        />
        <p>
          Story mode will explore the story of Solo Leveling, showing you how
          Sung Jinwoo became the strongest Hunter. The story works like a
          flowchart, where completing a chapter unlocks the next one. Some story
          chapters also unlock ‘side chapters’ that cover extra stories related
          to the supporting characters.
        </p>
        <h5>Achievements and Rewards</h5>
        <p>
          Story chapters provide a reward for clearing the chapter and for
          completing all the achievements in every mission, for a{' '}
          <strong>total of 4 standard banner Tickets per chapter.</strong>
        </p>
        <p>
          The achievement can range from the classic ‘clear within x seconds’ to
          more specific achievements, like ‘kill x monsters before the end of
          the time’ or ‘deal the last hit using this specific character’ or
          ‘using a hunter of said element’. This is especially true for the Side
          Chapters, where you will be forced to use some of the characters
          involved in the story and control them.
        </p>
        <p>
          If you do not own the character, the game will provide you a ‘free’
          copy to use for the mission, if you own the character then the game
          will use your own version, provided that it’s stronger compared to the
          free one. This is great as it was recently changed to consider the
          strongest between the two - early on you would be forced to spend a
          lot of gold to level up SR characters.
        </p>
        <p>
          After progressing enough in the story (chapter 8) you will{' '}
          <strong>unlock ‘Hard mode’</strong>. Hard mode is like normal mode,
          except it’s well, harder. The story segments will also be removed,
          only leaving the combat missions.
        </p>
        <p>
          <strong>
            Players should focus on completing chapter 8 as soon as possible
          </strong>
          , considering it unlocks an important mechanic, the Army of Shadows.
          Progressing with the story also unlocks the other game modes and gives
          a lot of XP, so it should be your priority!
        </p>
        <SectionHeader title="Game modes - Gates and gate mining" />
        <StaticImage
          src="../../../images/solo/generic/beginner_gates.webp"
          alt="Guide"
        />
        <p>
          Gates are instance dungeons that are generated periodically. Every
          gate has:
        </p>
        <ul>
          <li>A difficulty rank (from D-Rank to S-Rank),</li>
          <li>
            An entry condition (it’s either a solo gate where you use Sung or a
            gate where you can only use Hunters),
          </li>
          <li>
            A gate type. Currently, you can find Normal Gates, Dungeon Breaks
            gates, Treasure gates and Red Gates.
          </li>
        </ul>
        <p>
          If you don’t like the gates currently available you can
          <strong>rescan the available gates every 10 minutes</strong>. You can
          also pay some Essence Stones to rescan.
        </p>
        <p>
          As you increase your level you can progressively increase your
          difficulty rating - higher difficulty means more difficult gates but
          better rewards. At any time you can lower or increase the difficulty
          when doing a rescan, so don’t hesitate to do it if you want a bigger
          challenge or feel like you overestimated your power.
        </p>
        <p>
          Challenges may require you to clear specific gate types, so keep that
          in mind - mostly, they will ask you to clear ‘Dungeon breaks’ or ‘Red
          gates’. The game will show you the current progress so it’s very easy
          to see how close you are to the goal.{' '}
        </p>
        <h5>Gate Mining</h5>
        <StaticImage
          src="../../../images/solo/generic/beginner_mining.webp"
          alt="Guide"
        />
        <p>
          From the Gate menu you can also access the{' '}
          <strong>Gate Mining</strong> option.
        </p>
        <p>
          Mining a gate takes time depending on the gate rank and provides
          different rewards based on the rank and typing of the gate. Each gate
          mined will increase the XP of your miner team, and as you level it up
          you will gain a variety of useful perks.
        </p>
        <SectionHeader title="Game modes - Encore Missions" />
        <StaticImage
          src="../../../images/solo/generic/beginner_encore.webp"
          alt="Guide"
        />
        <p>
          Encore Missions are unlocked as you progress with the story and offer
          a way to obtain Artifacts, aka equipment.{' '}
          <strong>.You can only use Hunters in this mode.</strong>. There are
          two bosses per difficulty tier, one providing Helmets and Chest pieces
          and the other providing Gloves and Boots.
        </p>
        <p>
          Kasaka and the Mage Forces can drop Artifacts{' '}
          <strong>up to level 35</strong>, meanwhile Cerberus and Arachnid drop
          items between <strong>level 40 and 55</strong>.
        </p>
        <p>
          Every boss has different difficulty tiers, increasing the difficulty
          and the rewards you can obtain. If you can complete all the battle
          missions for a Tier, you will unlock a special buff that gives you 50%
          extra damage and reduces the damage received by 50%. This is huge and
          is great if you just want to auto through the boss at a lower
          difficulty to burn your keys instead of doing a higher difficulty
          manually.
        </p>
        <p>
          Depending on the character you rolled for you may found Cerberus to be
          quite harder compared to Arachnid, so focusing on the boss you can
          clear more easily first is a valid strategy to get power to push
          through the other boss more easily.{' '}
        </p>
        <p>
          Just make sure to alternate the two or you will risk not having pieces
          with the correct mainstay to gear your Hunters!
        </p>
        <SectionHeader title="Game modes - Instance Dungeons" />
        <StaticImage
          src="../../../images/solo/generic/beginner_instance.webp"
          alt="Guide"
        />
        <p>
          Instance Dungeons allow you to farm the 'right side' equipment, so
          accessories. And compared to Encore Missions where you could only use
          Hunters, <strong>here you can only use Sung Jinwoo</strong>.
        </p>
        <p>
          The Steel-fanged Lycan will drop Necklaces and Bracelets, meanwhile
          Igris will drop Rings and Earrings.
        </p>
        <p>
          Instance dungeons have the same format as Encore Missions, meaning you
          will progress through the tiers in order to get better accessories.
          Having a wide variety of breakers will make your progression much
          easier!
        </p>
        <SectionHeader title="Game modes - Others" />
        <StaticImage
          src="../../../images/solo/generic/beginner_bot.webp"
          alt="Guide"
        />
        <h5>Battlefield of Trials</h5>
        <p>
          This mode offers some challenging stages that you can clear for
          resources and draw tickets. Every 5 stages will reward you with 5
          tickets, so it’s a great source of pulls around midgame. There are two
          versions: one for Jinwoo and one for Hunters.
        </p>
        <StaticImage
          src="../../../images/solo/generic/beginner_bot2.webp"
          alt="Guide"
        />
        <h5>Battlefield of Time</h5>
        <p>
          A more ‘end-game’ focused mode, this mode lets you challenge bosses
          from story in a ranking mode, where you will receive rewards based on
          your personal best damage on top of a ranking reward based on your
          performance in your group.
        </p>
        <p>
          Bosses are unlocked over the season. Some of the challenge mode
          missions will require you to register a new best time in this mode, so
          you may want to play it more careful early on if you don’t want to get
          stuck. There is also a Challenge mode where you can get rewards by
          beating bossed of increasing difficulty.
        </p>
        <StaticImage
          src="../../../images/solo/generic/beginner_pod.webp"
          alt="Guide"
        />
        <h5>Power of Destruction</h5>
        <p>
          Power of Destruction is a raid battle where you fight a big boss,
          Pitch-black flame Ennio. The goal is to break his hands as fast as
          possible to deal damage on the end, rinse and repeat for the duration
          of the fight.
        </p>
        <p>
          Weapons that can inflict powerful debuffs like Poison/Bleed can deal a
          LOT of damage to the boss when you can target their head and it
          already caused the nerf of a popular cheese weapon, Kasaka’s Venom
          Fang, that could abuse this to insane levels.
        </p>
        <p>
          At the end of your run you can either retry the fight, go back to the
          menu or register it. After registering the score you will be added to
          the ranking and will be given a score+some rewards based on the point
          threshold you reached.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner Guide | Solo Leveling: Arise | Prydwen Institute"
    description="A beginner guide for Solo Leveling: Arise that will help you start strong in the game."
    game="solo"
  />
);
